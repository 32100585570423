import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import PAGES from "src/constants/pages";
import Logout from "./logout";
import Nav from "./nav";
import AppLogo from "../../assets/images/app-logo.svg";
import AppLogoBlue from "../../assets/images/app-logo-blue.svg";
import { CONFIG, hasLanguageSwitcher } from "../../constants/config";
import { ReactComponent as HeaderCharacter1 } from "../../assets/images/header-character-1-new.svg";
import { ReactComponent as HeaderCharacter2Mobile } from "../../assets/images/header-character-2-mobile.svg";
import { ReactComponent as HeaderDonut } from "../../assets/images/desktop-header-donut.svg";
import AuthHeaderCustom from "./auth_header_custom";
import LanguageSwitch from "./language_switch";
import { TRUNCATE } from "../../constants/truncateString";
import { utils } from "../../utils/utils_general";
import { utils_survey } from "src/utils/utils_survey.js";

class AuthHeader extends React.Component {
  get isMobileSurvey() {
    const { pathname } = this.props.location || "";
    return utils_survey.isSurveyPath(pathname) && utils.is_mobile();
  }

  renderHeader() {
    const { location } = this.props;
    const { pathname } = location || "";
    const sanitizedPath = pathname.replace("/", "");
    const path = sanitizedPath
      ? `header-${sanitizedPath?.replaceAll("/", "-")}`
      : "header";
    const showMobileHeaderCharacter =
      pathname === PAGES.DASHBOARD ||
      pathname === PAGES.PROFILE ||
      pathname === PAGES.ANCESTRY;
    const showDesktopHeaderCharacter = true; //! this.props.location.pathname.includes(PAGES.SURVEYS+'/');
    return (
      <header
        className={`header ${this.isMobileSurvey ? `header-survey-mobile pt-2 pb-1` : "pt-4 pb-3"} ${path}`}
      >
        <>
          {showDesktopHeaderCharacter ? (
            <div className="header-character-desktop">
              <HeaderCharacter1 />
            </div>
          ) : null}
          {showMobileHeaderCharacter ? (
            <div className="header-character-mobile">
              <HeaderCharacter2Mobile />
            </div>
          ) : null}

          <div className="header-donut-desktop">
            <HeaderDonut />
          </div>
        </>

        {this.renderNav()}

        <AuthHeaderCustom
          user={this.props.user}
          location={location}
          survey={this.props.selectedSurvey}
        />
        {this.props.children}
      </header>
    );
  }

  renderSmallName() {
    const first_name = this.props.user.first_name
      ? this.props.user.first_name
      : "";
    return (
      <div className="nav-sm-name name font-callout">
        {utils.truncate_string(first_name, TRUNCATE.NAME)}
      </div>
    );
  }

  renderNav() {
    const isAncestry = this.props.location.pathname === PAGES.ANCESTRY;
    const name = this.props.user.first_name
      ? this.props.user.last_name
        ? `${this.props.user.first_name} ${this.props.user.last_name}`
        : this.props.user.first_name
      : "";

    return (
      <>
        {!this.isMobileSurvey && (
          <div className="nav-mobile">
            <Nav />{" "}
          </div>
        )}
        <div
          className={`container-fluid pr-xl-5 pl-xl-5 ${isAncestry || this.isMobileSurvey ? "" : "pb-4"} `}
        >
          <div className="row">
            <div className="col-6 col-md-2 col-lg-3">
              <div className="header-logo">
                <Link to={PAGES.DASHBOARD}>
                  <img
                    src={isAncestry ? AppLogoBlue : AppLogo}
                    alt={`${CONFIG.APP_NAME} logo`}
                  />
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-8  col-lg-6 nav-desktop">
              {" "}
              <Nav />{" "}
            </div>
            {!this.isMobileSurvey && (
              <div className="col-6 col-md-2 col-lg-3 text-right header-signout">
                <div>
                  {hasLanguageSwitcher && !isAncestry ? (
                    <div className="nav-language">
                      {" "}
                      <LanguageSwitch />
                    </div>
                  ) : null}
                  {!isAncestry ? (
                    <div className="nav-name name font-callout">
                      {utils.truncate_string(name, TRUNCATE.NAME)}
                    </div>
                  ) : null}
                </div>
                <Logout />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  renderAncestryHeader() {
    return <div className="ancestry-header">{this.renderNav()}</div>;
  }

  render() {
    if (this.props.location.pathname === PAGES.ANCESTRY) {
      return this.renderAncestryHeader();
    }
    return this.renderHeader();
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  user: ownProps.user,
});

export default withRouter(connect(mapStateToProps, {})(AuthHeader));
