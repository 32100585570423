import React from "react";
import SurveySideDetails from "./survey_side_details";
import IconGenerator from "../icons/icon_generator";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PAGES from "src/constants/pages.js";

const SurveyWrapper = (props) => {
  const { survey, t } = props;
  if (!survey) {
    return null;
  }
  const { title, limesurvey_id } = survey;
  return (
    <section className="survey-take container">
      <div className="row">
        <aside className="col-12 col-xl-2 left-section">
          <SurveySideDetails survey={survey} />
        </aside>
        <div className="col-12 col-xl-8 center-section">
          <div className="mobile-only">
            <div className="mt-2 mb-2">
              <Link to={PAGES.SURVEYS} className="back-btn">
                <span className="mr-2">←</span> {t("Back to surveys")}
              </Link>
            </div>
            <h2 className="mb-0 mb-sm-3">{t(title)}</h2>

            <div className="survey-page-icon-wrapper">
              <IconGenerator iconKey={limesurvey_id} />
            </div>
          </div>

          {props.content}
        </div>
        <aside className="col-12 col-xl-2 right-section">
          <div className="survey-page-icon-wrapper">
            <IconGenerator iconKey={limesurvey_id} />
          </div>
        </aside>
      </div>
    </section>
  );
};

export default withTranslation()(SurveyWrapper);
