import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as IconHome } from "../assets/icons/History.svg";
import { ReactComponent as IconProfile } from "../assets/icons/Test.svg";
import { ReactComponent as IconSurveys } from "../assets/icons/Saliva.svg";
import { ReactComponent as IconEmail } from "../assets/icons/Ancestry.svg";
import { ReactComponent as IconClappingHand } from "../assets/icons/icon_high_five.svg";
import landingTick from "../assets/icons/check-circle.png";
import globeSmall from "../assets/images/ancestry-globe-small-blue-bg.gif";
import globeMedium from "../assets/images/ancestry-globe-medium-blue-bg.gif";
import globeLarge from "../assets/images/ancestry-globe-large-blue-bg.gif";

import PAGES from "src/constants/pages";
import { LandingPageReferralBannerB } from "./referral/landing-page-referral-banner-B";

import { CONFIG } from "src/constants/config";
import HtmlContentComponent from "./core/HtmlContent/HtmlContentComponent";
import { Link } from "react-router-dom";

export function LandingPage() {
  const { t } = useTranslation();

  function LandingCards() {
    return (
      <section id="section_1">
        <div className="landing-cards-wrapper">
          <div className="landing-cards-title">
            <div className="cards-content-title">
              <Trans i18nKey="Landing_page_title_1">
                All it takes to join this study are four steps
              </Trans>
            </div>
            <div className="content-description">
              <Trans i18nKey="Landing_page_title_2">
                All genders are welcome if you are 18 years or older and
                currently living in the United States
              </Trans>
            </div>
          </div>
          <div className="landing-cards-content row">
            <div className="col-sm-6 col-md-6 col-xl-3 card-border">
              <div className="landing-card">
                <div className="svg-icon">
                  <IconProfile />
                </div>
                <div className="card-description">
                  <h4>{t("Landing_Card_Step_1")}</h4>
                  <h3>{t("Landing_Card_Title_1")}</h3>
                  <p>{t("Landing_Card_Description_1")}</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-xl-3 card-border">
              <div className="landing-card">
                <div className="svg-icon">
                  <IconHome />
                </div>
                <div className="card-description">
                  <h4>{t("Landing_Card_Step_2")}</h4>
                  <h3>{t("Landing_Card_Title_2")}</h3>
                  <p>{t("Landing_Card_Description_2")}</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-xl-3 card-border">
              <div className="landing-card">
                <div className="svg-icon">
                  <IconSurveys />
                </div>
                <div className="card-description">
                  <h4>{t("Landing_Card_Step_3")}</h4>
                  <h3>{t("Landing_Card_Title_3")}</h3>
                  <p>{t("Landing_Card_Description_3")}</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-xl-3 card-border">
              <div className="landing-card">
                <div className="svg-icon">
                  <IconEmail />
                </div>
                <div className="card-description">
                  <h4>{t("Landing_Card_Step_4")}</h4>
                  <h3>{t("Landing_Card_Title_4")}</h3>
                  <p>{t("Landing_Card_Description_4")}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-3">
            <Link to={PAGES.SIGNUP} className="landing-cards-banner row">
              <div className="card-banner-left-text">
                {t("Landing_Cards_Banner_1")}
              </div>
              <IconClappingHand />
              <div className="card-banner-right-text">
                <div className="landing-card-banner-link">
                  {t("Landing_Cards_Banner_2")}
                  <i className="iconArrowRight" />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </section>
    );
  }

  function AncestryBanner() {
    const BannerBulletPoints = () => {
      return (
        <ul className="mb-4">
          <li className="mb-2 flex items-start">
            <img
              alt=""
              className="landing-tick inline-block align-top mr-2"
              src={landingTick}
            />
            {t("Landing_Ancestry_Banner_Bullet_1")}
          </li>
          <li className="mb-2 flex items-start">
            <img
              alt=""
              className="landing-tick inline-block align-top mr-2"
              src={landingTick}
            />
            {t("Landing_Ancestry_Banner_Bullet_2")}
          </li>
          <li className="mb-2 flex items-start">
            <img
              alt=""
              className="landing-tick inline-block align-top mr-2"
              src={landingTick}
            />
            {t("Landing_Ancestry_Banner_Bullet_3")}
          </li>
        </ul>
      );
    };

    return (
      <div className="landing-ancestry-banner-wrapper">
        <div className="landing-content landing-ancestry-banner-content">
          <div className="landing-ancestry-banner">
            <div className="row align-items-center">
              <div className="col-12 col-md-6 col-lg-5 col-xl-4 mb-5 mb-md-0">
                <h1 className="">
                  <Trans i18nKey="Landing_Ancestry_Banner_Title">
                    Unlock your ancestry and explore your roots
                  </Trans>
                </h1>
                <BannerBulletPoints />
                <Link
                  className="landing-ancestry-banner-button inline-block px-4 py-2 bg-blue-500 text-white rounded"
                  to={PAGES.ANCESTRY_CONTENT}
                >
                  {t("Landing_Ancestry_Banner_Button_1")}
                </Link>
              </div>
              <div className="col-12 col-md-6 col-lg-7 col-xl-8">
                <div
                  className="d-block d-md-none"
                  style={{ margin: "0 -15px" }}
                >
                  <img
                    className="img-fluid"
                    src={globeSmall}
                    alt="ancestry-globe-small"
                  />
                </div>
                <div className="d-none d-md-block d-lg-none">
                  <img
                    className="img-fluid"
                    style={{ minWidth: "332px" }}
                    src={globeMedium}
                    alt="ancestry-globe-medium"
                  />
                </div>
                <div className="d-none d-lg-block">
                  <img
                    className="img-fluid"
                    style={{ minWidth: "450px" }}
                    src={globeLarge}
                    alt="ancestry-globe-large"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function DataProtection() {
    return (
      // <section id="section_4">
      <div className="landing-data-protection-wrapper">
        <div className="landing-content landing-data-protection-content">
          <div className="landing-data-protection">
            <Trans i18nKey="Landing_Data_Protection_Title">
              <h2>You are in control of your data</h2>
            </Trans>
            <Trans i18nKey="Landing_Data_Protection_Description">
              <p>
                It’s your data, so you call the shots. From the moment you
                register your kit and set up your private account, you have
                meaningful choice in everything you do.{" "}
              </p>
            </Trans>
            <ul>
              <li>
                <img alt="" className="landing-tick" src={landingTick} />{" "}
                {t("Landing_Data_Protection_Bullet_1")}
              </li>
              <li>
                <img alt="" className="landing-tick" src={landingTick} />{" "}
                {t("Landing_Data_Protection_Bullet_2")}
              </li>
              <li>
                <img alt="" className="landing-tick" src={landingTick} />{" "}
                {t("Landing_Data_Protection_Bullet_3")}
              </li>
            </ul>
            <div className="landing-hero-links">
              <Link
                to={PAGES.DATA_PROTECTION}
                className="landing-data-protection-link"
              >
                {t("Landing_Data_Protection_Button_1")}{" "}
                <i className="icon-arrow-right" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      // </section>
    );
  }

  function LandingSmallCards() {
    return (
      <div className="d-sm-none landing-content landing-sm-cards-content">
        <div className="landing-cards-title">
          <div className="cards-content-title">
            <Trans i18nKey="Landing_page_title_1">
              All it takes to join this study are four steps
            </Trans>
          </div>
          <div className="content-description">
            <Trans i18nKey="Landing_page_title_2">
              All genders are welcome if you are 18 years or older and currently
              living in the United States
            </Trans>
          </div>
        </div>
        <div className="landing-card">
          <IconProfile />
          <div className="card-description">
            <h4>{t("Landing_Card_Step_1")}</h4>
            <h3>{t("Landing_Card_Title_1")}</h3>
            <p>{t("Landing_Card_Description_1")}</p>
          </div>
        </div>
        <div className="landing-card">
          <IconHome />
          <div className="card-description">
            <h4>{t("Landing_Card_Step_2")}</h4>
            <h3>{t("Landing_Card_Title_2")}</h3>
            <p>{t("Landing_Card_Description_2")}</p>
          </div>
        </div>
        <div className="landing-card">
          <IconSurveys />
          <div className="card-description">
            <h4>{t("Landing_Card_Step_3")}</h4>
            <h3>{t("Landing_Card_Title_3")}</h3>
            <p>{t("Landing_Card_Description_3")}</p>
          </div>
        </div>
        <div className="landing-card">
          <IconEmail />
          <div className="card-description">
            <h4>{t("Landing_Card_Step_4")}</h4>
            <h3>{t("Landing_Card_Title_4")}</h3>
            <p>{t("Landing_Card_Description_4")}</p>
          </div>
        </div>
        <div className="landing-card">
          <div className="landing-sm-cards-banner">
            <div className="card-banner-left-text">
              {t("Landing_Cards_Banner_1")}
            </div>
            <div className="card-banner-right-text">
              <Link to={PAGES.SIGNUP} className="landing-card-link">
                {t("Landing_Cards_Banner_2")}
                <i className="iconArrowRight" />
              </Link>
            </div>
            <IconClappingHand />
          </div>
        </div>
      </div>
    );
  }

  function LandingTopic() {
    return (
      <section id="section_2">
        <div className="landing-topic-wrapper">
          <div className="landing-content landing-topic-content">
            <div className="landing-topic">
              <Trans i18nKey="Landing_Topic_2_Title">
                <h2>Learn about Population &amp; Genetics</h2>
              </Trans>
              <ul>
                <li>
                  <img alt="" className="landing-tick" src={landingTick} />{" "}
                  {t("Landing_Topic_2_bullet_1")}
                </li>
                <li>
                  <img alt="" className="landing-tick" src={landingTick} />{" "}
                  {t("Landing_Topic_2_bullet_2")}
                </li>
                <li>
                  <img alt="" className="landing-tick" src={landingTick} />{" "}
                  {t("Landing_Topic_2_bullet_3")}
                </li>
                <li>
                  <img alt="" className="landing-tick" src={landingTick} />{" "}
                  {t("Landing_Topic_2_bullet_4")}
                </li>
                <li>
                  <img alt="" className="landing-tick" src={landingTick} />{" "}
                  {t("Landing_Topic_2_bullet_5")}
                </li>
              </ul>
              <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center landing-hero-links">
                <Link
                  to={PAGES.SIGNUP}
                  className="landing-header-button mb-2 mb-md-0 mr-md-2"
                >
                  {t("Landing_Topic_2_button_1")}
                </Link>
                <Link to={PAGES.ABOUT_US} className="landing-header-link">
                  {t("Landing_Topic_2_button_2")}{" "}
                  <i className="icon-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  function LandingAbout() {
    return (
      <section id="section_3">
        <div className="landing-about-wrapper">
          <div className="landing-content text-center">
            <HtmlContentComponent
              markup={t("Landing_About_Study_Title")}
            ></HtmlContentComponent>
            <div className="p-5 row">
              <div className="col col-sm col-about">
                <HtmlContentComponent
                  markup={t("Landing_About_Study_Subtitle_1")}
                ></HtmlContentComponent>
              </div>
              <div className="col col-sm col-about">
                <HtmlContentComponent
                  markup={t("Landing_About_Study_Subtitle_2")}
                ></HtmlContentComponent>
              </div>
            </div>
            <HtmlContentComponent
              markup={t("Landing_About_Study_Description")}
            ></HtmlContentComponent>
          </div>
        </div>
      </section>
    );
  }

  function LandingHeader() {
    return (
      <div className="landing-header-wrapper">
        <div className="landing-header-content">
          <div className="header-content-wrapper">
            <div className="header-landing-content">
              <div className="content-title">
                <Trans i18nKey="Landing_page_title">
                  Join the fight against Breast Cancer
                </Trans>
              </div>
              <div className="content-subtitle">
                <Trans i18nKey="Landing_page_sub_title">
                  Your saliva can help fight cancer
                </Trans>
              </div>
              <div className="pb-4 content-description">
                <Trans i18nKey="Landing_page_description">
                  <p>
                    Whether or not you have had cancer, become a volunteer to
                    help scientists learn more about how genes affect risk for
                    breast cancer. The more people who join, the easier it will
                    be to crack the code.
                  </p>
                </Trans>
              </div>
              <div className="landing-hero-links">
                <Link to={PAGES.SIGNUP} className="landing-hero-button">
                  {t("Landing_page_sign_up")}
                </Link>
              </div>
            </div>
          </div>
          <LandingCards />
        </div>
      </div>
    );
  }

  return (
    <section className="landing-page">
      <LandingHeader />
      <LandingSmallCards />
      <LandingTopic />
      <LandingAbout />

      {CONFIG.REFERRAL && CONFIG.REFERRAL_LANDING_PAGE_BANNER ? (
        <div className="landing-referral-banner-wrapper">
          <div className="landing-content landing-content-banner text-left">
            <LandingPageReferralBannerB />
          </div>
          <div className="referral-banner-wrapper" />
        </div>
      ) : null}

      {CONFIG.FF_LANDING_PAGE_DATA_PROTECTION ? (
        <section id="section_4">
          {" "}
          <DataProtection />{" "}
        </section>
      ) : null}

      {CONFIG.FF_LANDING_PAGE_ANCESTRY_BANNER ? (
        <section id="section_5">
          <AncestryBanner />
        </section>
      ) : null}

      {CONFIG.REFERRAL_LANDING_NEWSLETTER ? (
        <section
          id={!CONFIG.FF_LANDING_PAGE_DATA_PROTECTION ? "section_4" : null}
        >
          <div className="landing-footer-wrapper">
            <div className="landing-content d-flex text-center">
              <div className="col-sm d-flex justify-content-center align-items-center">
                <div className="landing-footer-text text-left mb-sm-0">
                  <Trans i18nKey="signupNewsletterAction">
                    {t("Landing_Population_Newsletter_text")}
                  </Trans>
                </div>
              </div>
              <div className="col-sm text-left d-flex align-items-center">
                <div className="landing-footer-input-wrapper">
                  <input type="text" placeholder={t("Enter your email")} />
                  <button className="landing-footer-input-btn">
                    {t("Landing_Population_Newsletter_Send")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </section>
  );
}

export default LandingPage;
