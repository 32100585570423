import { useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
// import { ReactComponent as CopyIcon } from "../../assets/icons/copy-icon.png"
import CopyIcon from "../../assets/icons/copy-icon.png";
import { CONFIG } from "src/constants/config";

import styles from "./referral-link-B.module.scss";

function highlight(id) {
  var range = document.createRange();
  range.selectNode(document.getElementById(id));
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(range);
}

async function copyToClipboard(text) {
  try {
    // TODO fallback
    await navigator.clipboard.writeText(text);
    return true;
  } catch (e) {
    console.log("error trying to copy to clipboard");
    return false;
  }
}

export function ReferralLinkSmallB() {
  const user = useSelector((state) => state.loginUser);
  const { t } = useTranslation();
  let referralUrl = `${CONFIG.APP_BASE_URL}/referral`;
  if (CONFIG.REFERRAL_URL) {
    referralUrl = CONFIG.REFERRAL_URL;
    if (CONFIG.REFERRAL_URL_PREFIX && user?.utm_share_code) {
      referralUrl = `${CONFIG.REFERRAL_URL_PREFIX}${user.utm_share_code}`;
    }
  }
  const [isCopied, setIsCopied] = useState(false);
  const onClick = async () => {
    if (await copyToClipboard(referralUrl)) {
      setIsCopied(true);
    }
  };
  return navigator.clipboard ? (
    // Just a button to copy the link
    <Button className="primary" variant="primary" onClick={onClick}>
      {isCopied ? t("Link copied!") : t("Copy link")}
    </Button>
  ) : (
    // Show the link as a fallback
    <div
      id="referral-url"
      type="text"
      className={classNames(
        styles.referralLink,
        styles.referralLinkFallback,
        "bg-white",
      )}
      onClick={() => highlight("referral-url")}
    >
      {referralUrl}
    </div>
  );
}

export function ReferralLinkB(props) {
  const { withBorder } = props;
  const user = useSelector((state) => state.loginUser);
  // Show link and a button to copy it
  const { t } = useTranslation();
  let referralUrl = `${CONFIG.APP_BASE_URL}/referral`;
  if (CONFIG.REFERRAL_URL) {
    referralUrl = CONFIG.REFERRAL_URL;
    if (CONFIG.REFERRAL_URL_PREFIX && user?.utm_share_code) {
      referralUrl = `${CONFIG.REFERRAL_URL_PREFIX}${user.utm_share_code}`;
    }
  }
  const [isCopied, setIsCopied] = useState(false);
  const onClick = async () => {
    if (await copyToClipboard(referralUrl)) {
      setIsCopied(true);
    }
  };
  return (
    <div className={classNames(styles.copyButton, "input-group")}>
      <div
        id="referral-url"
        type="text"
        className={classNames(
          styles.referralLink,
          withBorder ? styles.withBorder : undefined,
          "bg-white",
        )}
        onClick={() => highlight("referral-url")}
      >
        {referralUrl}
      </div>
      {navigator.clipboard ? (
        <div className="input-group-append">
          <button
            className="pt-3 btn btn-referral-link btn-sm input-group-text"
            type="button"
            onClick={onClick}
          >
            {isCopied ? (
              t("Copied!")
            ) : (
              <div>
                <img
                  alt=""
                  className={classNames(styles.copyIcon)}
                  src={CopyIcon}
                />
                {t("Copy link")}
              </div>
            )}
          </button>
        </div>
      ) : null}
    </div>
  );
}
