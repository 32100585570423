import { useState, useRef, useEffect, forwardRef } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { utils } from "../../utils/utils_general";
import Spinner from "../global/spinner";
import { utils_workflow } from "../../utils/utils_workflow";
import PAGES from "../../constants/pages";
import HtmlContentComponent from "../core/HtmlContent/HtmlContentComponent";
import EMRModal from "./emr_modal";

import {
  get_emr_data,
  get_emr_connection,
  post_emr_feedback,
} from "../../actions/emrAction";
import { questionnaire_resp_post } from "../../actions/questionnaireAction";
import {
  post_user_attributes_post,
  get_user_attributes_get,
} from "../../actions/userAction";
import defaultLogoUrl from "../../assets/images/health-system-default.png";
import moreIconUrl from "../../assets/icons/More.svg";
import searchHospitalIconUrl from "../../assets/icons/SearchHospital.svg";
import noDoctorIconUrl from "../../assets/icons/NoDoctor.svg";
import checkIconUrl from "../../assets/icons/Check.svg";

import ehrImgUrl from "../../assets/images/EHR.svg";
import discoveryImgUrl from "../../assets/images/Discovery.svg";
import securelyImgUrl from "../../assets/images/Securely.svg";
import searchEngineImgUrl from "../../assets/images/SearchEngine.svg";
import superHeroImgUrl from "../../assets/images/superhero.svg";

import emr2Styles from "./emr2.module.css";

const EMR_STARTED_ATTR = "emr_started";
const RESULTS_PAGE_SIZE = 20;

// TODO: refactor this file

export function EMR2(props: any) {
  const {
    onWorkflowSkip,
    step,
    onComplete,
    isWorkflowNext,
    onWorkflowExit,
    showIntro,
    onShowIntro,
  } = props;
  const history = useHistory();

  // initial load existing connections, user attributes
  // post emr_started = true
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    const initialLoad = async () => {
      setLoading(true);
      try {
        await Promise.all([
          // @ts-ignore
          dispatch(post_user_attributes_post(EMR_STARTED_ATTR, true, true)),
          dispatch(get_emr_connection()),
          // @ts-ignore
          dispatch(get_user_attributes_get(true)),
        ]);
      } catch (error) {
        console.log("error", error);
      }
      setLoading(false);
    };
    initialLoad();
  }, [dispatch]);

  const emr_confirmed = useSelector(
    // @ts-ignore
    (state) => state.userAttribute?.emr_confirmed,
  );

  const setStepExitAttributes = (step: any) => {
    let exitAttributeRequests: any[] = [];
    if (step !== undefined) {
      const exitAttributes = utils_workflow.getPostAttributes(
        step.exit_attribute,
      );
      exitAttributeRequests = Object.entries(exitAttributes).map(([key]) =>
        dispatch(post_user_attributes_post(key, true)),
      );
    }
    return Promise.all(exitAttributeRequests);
  };

  const exitStep = () => {
    if (!isWorkflowNext) {
      history.push(PAGES.DASHBOARD);
    }
  };

  const workflowExit = async () => {
    if (typeof onWorkflowExit === "function") {
      await onWorkflowExit();
    }
    exitStep();
  };

  const onFormComplete = async () => {
    // From original code: TODO: maybe find a better way to do this...
    // NOTE: I am not sure if this is still required
    const attr = "temp_emr_seen";
    // @ts-ignore
    await dispatch(post_user_attributes_post(attr, true));
    await onComplete();
    workflowExit();
  };

  const onConfirmEMR = async (confirm: boolean) => {
    // TODO: not sure if we want to get attributes here
    // @ts-ignore
    await dispatch(get_user_attributes_get(true));
    if (!confirm) {
      if (onWorkflowSkip) {
        await onWorkflowSkip();
      } else {
        await setStepExitAttributes(step);
        await onFormComplete();
      }
      onShowIntro(false);
      history.push(PAGES.DASHBOARD);
    } else {
      onShowIntro(false);
    }
  };

  const skipEMR = async () => {
    const attr = "temp_emr_seen";
    await post_user_attributes_post(attr, true);
    await onComplete();
    if (isWorkflowNext && typeof onWorkflowSkip === "function") {
      onWorkflowSkip();
      exitStep();
      return null;
    }
    workflowExit();
  };

  if (loading) {
    // Not showing spinner here to avoid double spinner
    return <div className={classNames(emr2Styles.spinner)}></div>;
  }

  // return !this.state.userConfirmed
  if (!emr_confirmed || showIntro) {
    // agree to connect emr first
    return <ConfirmEMR onConfirmEMR={onConfirmEMR} />;
  }

  return (
    <div>
      <EMRSearch onSkip={skipEMR} />
    </div>
  );
}

type ConfirmEMRStep = {
  title: string;
  imageUrl: string;
  subTitle: string;
  content: string;
};

function ConfirmEMRScreen(props: ConfirmEMRStep) {
  const { title, imageUrl, subTitle, content } = props;
  return (
    <>
      <h2
        className={classNames(
          "font-weight-bold mt-3 mb-3",
          emr2Styles.onboardingTitle,
        )}
      >
        {title}
      </h2>
      <img src={imageUrl} alt="" />
      <p
        className={classNames(
          "font-weight-bold py-3",
          emr2Styles.onboardingSubTitle,
        )}
      >
        {subTitle}
      </p>
      <HtmlContentComponent
        className={classNames(emr2Styles.htmlContent)}
        markup={content}
      />
    </>
  );
}

type ConfirmEMRProps = {
  onConfirmEMR: (confirm: boolean) => void;
};

function ConfirmEMR(props: ConfirmEMRProps) {
  const { onConfirmEMR } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const confirmEmrSend = async (confirm = true) => {
    const code = confirm ? "emr_confirm_yes" : "emr_confirm_no";
    const resp = [{ question: "emr_confirm", answers: [code] }];
    await dispatch(questionnaire_resp_post("emr", resp));
    onConfirmEMR(confirm);
  };

  const steps: {
    [step: number]: JSX.Element;
  } = {
    1: (
      <ConfirmEMRScreen
        title={t("emr_intro_step1_title")}
        imageUrl={ehrImgUrl}
        subTitle={t("emr_intro_step1_subTitle")}
        content={t("emr_intro_step1_content", { nsSeparator: "#" })}
      />
    ),
    2: (
      <ConfirmEMRScreen
        title={t("emr_intro_step2_title")}
        imageUrl={discoveryImgUrl}
        subTitle={t("emr_intro_step2_subTitle")}
        content={t("emr_intro_step2_content", { nsSeparator: "#" })}
      />
    ),
    3: (
      <ConfirmEMRScreen
        title={t("emr_intro_step3_title")}
        imageUrl={securelyImgUrl}
        subTitle={t("emr_intro_step3_subTitle")}
        content={t("emr_intro_step3_content", { nsSeparator: "#" })}
      />
    ),
    4: (
      <ConfirmEMRScreen
        title={t("emr_intro_step4_title")}
        imageUrl={searchEngineImgUrl}
        subTitle={t("emr_intro_step4_subTitle")}
        content={t("emr_intro_step4_content", { nsSeparator: "#" })}
      />
    ),
    5: (
      <ConfirmEMRScreen
        title={t("emr_intro_step5_title")}
        imageUrl={superHeroImgUrl}
        subTitle={t("emr_intro_step5_subTitle")}
        content={t("emr_intro_step5_content", { nsSeparator: "#" })}
      />
    ),
  };

  return (
    <div className="">
      <div className="row">
        <div className="col-12 col-sm-12 pb-4 text-center">{steps[step]}</div>
      </div>
      {step < 5 ? (
        <div className="text-center">
          <Button
            variant="primary"
            className={classNames("px-3 mb-3", emr2Styles.nextButton)}
            onClick={() => {
              setStep(step + 1);
            }}
          >
            {t("Next")}
          </Button>
        </div>
      ) : (
        <>
          <div className="text-center">
            <Button
              variant="primary"
              className={classNames("px-3 mb-2", emr2Styles.nextButton)}
              onClick={() => {
                confirmEmrSend(true);
              }}
            >
              {t("Let’s do this")}
            </Button>
          </div>
          <div className="text-center">
            <Button
              variant="link"
              className={classNames("px-3 mb-3", emr2Styles.nextButton)}
              onClick={() => {
                confirmEmrSend(false);
              }}
            >
              {t("Maybe later")}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

function EMRSearch(props: any) {
  const { onSkip } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);

  // modal
  const [modalShown, setModalShown] = useState(false);
  const [modalItem, setModalItem] = useState(null);
  const initEHR = () => {
    setModalItem(null);
    setModalShown(false);
    setConnectComplete(false);
    setResults(null);
    setSearchError(null);
    setResultsLimit(RESULTS_PAGE_SIZE);
  };
  const openEhrModal = (item: any) => {
    setConnectionId(item.id);
    setModalItem(item);
    setModalShown(true);
  };
  const handleConnectionComplete = () => {
    setModalShown(false);
    setModalItem(null);
    setConnectComplete(true);
  };
  const handleConnectionError = () => {
    // Don't do anything here, error message is displayed in the modal
  };
  const handleCloseEhrModal = () => {
    setModalShown(false);
    setModalItem(null);
  };
  const [connectionId, setConnectionId] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  // search
  const [searchError, setSearchError] = useState<
    "search_error" | "search_error_short_query" | null
  >(null);
  const [lastQuery, setLastQuery] = useState<string | null>(null);
  const [results, setResults] = useState<any[] | null>(null);

  // track card to focus after "load more"
  const firstLoadedCardRef = useRef<HTMLButtonElement>(null);

  const handleSearchSubmit = async (e: any) => {
    e.preventDefault();
    inputRef.current?.blur();
    const query = inputRef.current?.value;

    setResults(null);
    setSearchError(null);
    setLoading(true);
    setResultsLimit(RESULTS_PAGE_SIZE);
    if (query === undefined || query.trim() === "") {
      setSearchError("search_error_short_query");
      setResults(null);
      setLoading(false);
      return;
    }
    if (query.trim().length < 2) {
      setSearchError("search_error_short_query");
      setLoading(false);
      return;
    }
    setLastQuery(query);
    try {
      const res: any = await dispatch(get_emr_data(query, true));
      if (!res.data?.length) {
        dispatch(
          post_emr_feedback({
            search_terms: query?.trim() || "",
            search_type: "healthsystem",
          }),
        );
      }
      setResults(res.data);
      setLoading(false);
    } catch (err) {
      setSearchError("search_error");
      setLoading(false);
    }
  };

  const [thankYouModalShown, setThankYouModalShown] = useState(false);
  const [thankYouModalLoading, setThankYouModalLoading] = useState(false);
  const handleCantFind = async () => {
    setThankYouModalLoading(true);
    setThankYouModalShown(true);
    if (lastQuery) {
      try {
        const action = post_emr_feedback({
          search_terms: lastQuery.trim() || "",
          search_type: "healthsystem",
          feedback: "User submitted",
        });
        // @ts-ignore
        await dispatch(action);
      } catch (error) {
        console.error(error);
      } finally {
        setThankYouModalLoading(false);
      }
    }
    // show thank you modal
  };

  const [connectComplete, setConnectComplete] = useState(false);
  // @ts-ignore
  const connections = useSelector((state) => state.emrConnections.data);

  // state filter
  const [stateFilter, setStateFilter] = useState("all");
  const [resultsLimit, setResultsLimit] = useState(RESULTS_PAGE_SIZE);

  let resultsWithConnected: any = null;
  let resultsSlice: any = null;
  if (results) {
    resultsWithConnected = [];
    results.forEach((item) => {
      const withConnectedItem = item;
      connections.forEach((existingItem: any) => {
        if (existingItem.id === item.id) {
          withConnectedItem.connected = true;
          withConnectedItem.updated = existingItem.updated;
        }
      });
      resultsWithConnected.push(withConnectedItem);
    });
    if (stateFilter !== "all") {
      resultsWithConnected = resultsWithConnected.filter((item: any) => {
        return stateFilter === item.state;
      });
    }
    // limit results
    resultsSlice = resultsWithConnected.slice(0, resultsLimit);
  }

  const handleLoadMore = () => {
    setResultsLimit(resultsLimit + RESULTS_PAGE_SIZE);
    setTimeout(() => {
      firstLoadedCardRef.current?.focus();
    }, 0);
  };

  if (connectComplete) {
    // http://localhost:3000/emr/complete?success=true&iss=https://fhir-myrecord.cerner.com/r4/ec2458f2-1e24-41c8-b71b-0e701af7583d
    // TODO: IconPage component
    const lastConnection = connections.find((c: any) => c.id === connectionId);
    return (
      <div>
        <div className="d-flex flex-column align-items-center p-4">
          <img src={checkIconUrl} alt="" />
          <h4
            className={classNames(
              "text-center mb-3",
              emr2Styles.iconPageTitleH4,
            )}
          >
            {t("You’ve shared your EHR successfully")}
          </h4>
          <p className="text-center mb-0">
            {t("You can view your shared EHR in your profile screen")}
          </p>
        </div>

        {lastConnection ? (
          <div className="mb-5">
            <EMRListItem2
              showConnectButton={false}
              connected={true}
              connectOnClick={() => {
                openEhrModal(modalItem);
              }}
              item={lastConnection}
            />
          </div>
        ) : null}

        <p className="font-weight-bold text-center">
          {t(
            "Connecting your medical record is the key in our battle against cancer. Would you like to connect another?",
          )}
        </p>
        <div className="text-center mb-2">
          <Button
            variant="primary"
            size="sm"
            type="button"
            className={classNames("px-3 py-2", emr2Styles.roundedSm)}
            onClick={() => {
              initEHR();
            }}
          >
            {t("Yes, let’s connect another!")}
          </Button>
        </div>
        <div className="text-center mb-3">
          <Button
            className={classNames("px-3 py-2")}
            variant="link"
            size="sm"
            onClick={() => {
              onSkip();
            }}
          >
            {t("Skip this for now")}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      {/* search form */}
      <div>
        <SearchForm
          inputRef={inputRef}
          onSubmit={handleSearchSubmit}
          onStateFilter={(code) => {
            setStateFilter(code);
          }}
          searchError={searchError}
        />
      </div>

      {/* search results */}
      {loading ? (
        <div
          className={classNames(
            "align-items-center position-relative",
            emr2Styles.spinner,
          )}
        >
          <Spinner />
        </div>
      ) : resultsWithConnected === null ? (
        // no search
        <div className="d-flex flex-column align-items-center py-5">
          <div className="pb-3">
            <img
              src={searchHospitalIconUrl}
              alt=""
              className={emr2Styles.scale10pct}
            />
          </div>
          <div
            className={classNames(
              emr2Styles.noResults,
              "text-center pb-4",
              emr2Styles.textWrapBalance,
              emr2Styles.fontSizeMedium,
            )}
          >
            {t(
              "Try searching by Health System, Hospital, Provider or Practice name",
            )}
          </div>
          <Button
            className={classNames(
              "font-weight-normal",
              emr2Styles.linkButton,
              emr2Styles.fontSizeMedium,
            )}
            variant="link"
            size="sm"
            onClick={() => {
              onSkip();
            }}
          >
            {t("Skip this for now")}
          </Button>
        </div>
      ) : resultsWithConnected.length === 0 ? (
        // search without results
        <div className="d-flex flex-column align-items-center py-5">
          <div className="pb-1">
            <img
              src={noDoctorIconUrl}
              alt=""
              className={emr2Styles.scale10pct}
            />
          </div>
          <div
            className={classNames(
              emr2Styles.noResults,
              emr2Styles.fontSizeMedium,
              "text-center font-weight-bold pb-1",
              emr2Styles.textWrapBalance,
            )}
          >
            {t("Sorry! We couldn't find any results")}
          </div>
          <div
            className={classNames(
              emr2Styles.noResults,
              "text-center pb-4",
              emr2Styles.textWrapBalance,
              emr2Styles.fontSizeMedium,
            )}
          >
            {t(
              "Try modifying your search terms. If you still can’t find who you are looking for, please let us know.",
            )}
          </div>
          <div>
            <Button
              variant="primary"
              size="sm"
              type="button"
              className={classNames(
                "px-3 py-2",
                emr2Styles.roundedSm,
                emr2Styles.fontSizeMedium,
              )}
              onClick={() => {
                handleCantFind();
              }}
            >
              {t("Can’t find what you are looking for?")}
            </Button>
          </div>
          <div className="mt-5">
            <Button
              className={classNames(
                "font-weight-normal",
                emr2Styles.linkButton,
                emr2Styles.fontSizeMedium,
              )}
              variant="link"
              size="sm"
              onClick={() => {
                onSkip();
              }}
            >
              {t("Skip this for now")}
            </Button>
          </div>
        </div>
      ) : (
        // search with results
        <div>
          {/* results header */}
          <div className="d-flex justify-content-between py-2">
            <small>
              {t("{{count}} results", { count: resultsWithConnected.length })}
            </small>
            <small>
              <Button
                className={classNames(
                  "font-weight-normal",
                  emr2Styles.linkButton,
                )}
                variant="link"
                size="sm"
                onClick={() => {
                  onSkip();
                }}
              >
                {t("Skip this for now")}
              </Button>
            </small>
          </div>

          {/* system list */}
          <div
            className={classNames("d-flex flex-column", emr2Styles.systemList)}
          >
            {resultsSlice.map((item: any, index: any) => {
              // add ref to the first loaded item
              const ref =
                index === resultsSlice.length - RESULTS_PAGE_SIZE
                  ? firstLoadedCardRef
                  : undefined;
              return (
                <EMRListItem2
                  ref={ref}
                  key={index}
                  connected={item.connected}
                  connectOnClick={() => {
                    openEhrModal(item);
                  }}
                  item={item}
                  showConnectButton={true}
                />
              );
            })}
          </div>
          {resultsSlice.length < resultsWithConnected.length ? (
            <div className="text-center pt-4 pb-2">
              <Button
                variant="primary"
                size="sm"
                type="button"
                className={classNames("px-3 py-2", emr2Styles.roundedSm)}
                onClick={() => {
                  handleLoadMore();
                }}
              >
                {t("Load more")}
              </Button>
            </div>
          ) : null}
          <div className="text-center py-3">
            <small>
              <Button
                className={classNames(
                  "font-weight-normal py-2",
                  emr2Styles.linkButton,
                )}
                variant="link"
                size="sm"
                onClick={() => {
                  handleCantFind();
                }}
              >
                {t("Can’t find your results?")}
              </Button>
            </small>
          </div>
        </div>
      )}

      <EMRModal
        // @ts-ignore: HOC types are messed up
        show={modalShown}
        modalItem={modalItem}
        onConnectionComplete={handleConnectionComplete}
        onConnectionError={handleConnectionError}
        onCloseClick={handleCloseEhrModal}
      />

      <ThankYouModal
        show={thankYouModalShown}
        loading={thankYouModalLoading}
        onSearchAnother={() => {
          if (inputRef.current) {
            inputRef.current.value = "";
          }
          initEHR();
          setThankYouModalShown(false);
        }}
        onSkip={onSkip}
      />
    </div>
  );
}

// TODO: type EMRListItem2Props
const EMRListItem2 = forwardRef<HTMLButtonElement, any>(function EMRListItem2(
  props: any,
  ref,
) {
  const { item, connected, connectOnClick, showConnectButton = true } = props;
  const [logoError, setLogoError] = useState(false);
  const { t } = useTranslation();

  // Attempt to fix logo url if it comes broken
  const logoUrl = fixLogoUrl(item.logo);

  const cardContent = (
    <>
      {/* main content */}
      <div className={classNames("d-flex w-100", emr2Styles.mainContent)}>
        {/* Logo */}
        <div className={emr2Styles.searchCardLogo}>
          <div
            className={classNames("d-flex align-items-center", emr2Styles.logo)}
          >
            <img
              src={logoError ? defaultLogoUrl : logoUrl}
              alt=""
              className="img-fluid"
              // fallback on logo loading error
              // https://www.codu.co/articles/setting-a-fallback-image-in-html-for-broken-or-missing-images-otom_bhg
              onError={
                !logoError
                  ? () => {
                      setLogoError(true);
                    }
                  : undefined
              }
            />
          </div>
        </div>
        {/* Name, system, address */}
        <div className="flex-fill text-left">
          <div
            className={classNames(
              "font-weight-bold",
              emr2Styles.textWrapBalance,
            )}
          >
            {item.name || t("Health Institution")}
          </div>
          {item.healthSystem ? (
            <p className="mb-0">
              <small>{item.healthSystem}</small>
            </p>
          ) : null}
          <small className="text-muted">
            {`${[item.city, item.state, item.zipcode].filter((x) => Boolean(x)).join(", ")}`}
          </small>
        </div>
        {showConnectButton ? (
          <div
            className={classNames(
              emr2Styles.connectButton,
              "p-2 p-sm-0 border-0 align-self-sm-center",
            )}
            onClick={() => connectOnClick(item)}
          >
            <img src={moreIconUrl} alt="Connect" />
          </div>
        ) : null}
      </div>

      {/* footer*/}
      {connected ? (
        <div
          className={classNames(
            "w-100 text-muted pt-2 text-left",
            emr2Styles.lastShared,
          )}
        >
          {/* Connection info */}
          <small>
            {t("Last Shared")}: {item.updated ? formatDate(item.updated) : ""}
          </small>
        </div>
      ) : null}
    </>
  );

  if (showConnectButton) {
    return (
      <button
        ref={ref}
        className={classNames(
          "d-flex flex-column rounded p-3 border-0 w-100",
          emr2Styles.searchCard,
          emr2Styles.searchCardClickable,
        )}
        onClick={() => connectOnClick(item)}
      >
        {cardContent}
      </button>
    );
  }
  return (
    <div
      className={classNames(
        "d-flex flex-column rounded p-3 border-0 w-100",
        emr2Styles.searchCard,
      )}
    >
      {cardContent}
    </div>
  );
});

const dateTimeFormat = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "short",
  day: "numeric",
});

function formatDate(dateString: any) {
  const date = new Date(dateString.slice(0, 10));
  return dateTimeFormat.format(date);
}

function fixLogoUrl(sourceUrl: any) {
  // Attempt to fix invalid URL
  //
  // Sometimes 1uphealth returns broken urls for the providers logos:
  //
  // "name": "Columbus Skin Surgery Center",
  // "logo": "undefined/health-system-default.png",
  //
  // "name": "Commonwealth Pain Specialists",
  // "logo": "undefined/null",
  //
  // "name": "Community Health Mark",
  // "logo": "undefined/https://1uphealth-assets.s3-us-west-2.amazonaws.com/systems/health-system-default.png",
  //
  // "name": "Cerner Health Systems (demo)",
  // "logo": "undefined/cerner.png",
  //
  // "name": "CommonSpirit Health - Mountain Region",
  // "logo": "undefined/https://media.licdn.com/dms/image/D560BAQF5nVUzhuA-0g/company-logo_200_200/0/1719855657083/commonspirithealth_logo?e=1729123200&v=beta&t=oVMGp8YDxzMF4-KTU422nONFFa8cIxwrj2VLLkUj9nE",
  //
  // "name": "Blue Cross Blue Shield Nebraska",
  // "logo": null,

  const baseUrl = "https://1uphealth-assets.s3-us-west-2.amazonaws.com/systems";
  const noLogoUrls = [
    "health-system-default.png",
    "null",
    "https://1uphealth-assets.s3-us-west-2.amazonaws.com/systems/health-system-default.png",
  ];

  if (!sourceUrl) {
    return defaultLogoUrl;
  }

  if (sourceUrl?.startsWith("undefined")) {
    const url = sourceUrl.replace("undefined/", "");
    if (noLogoUrls.includes(url)) {
      return defaultLogoUrl;
    }
    if (!url.startsWith("http")) {
      return `${baseUrl}/${url}`;
    }
    return url;
  }

  return sourceUrl;
}

type SearchFormProps = {
  onSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
  inputRef: React.RefObject<HTMLInputElement>;
  onStateFilter: (code: string) => void;
  searchError: string | null;
};

function SearchForm(props: SearchFormProps) {
  const { onSubmit, inputRef, onStateFilter, searchError } = props;

  const { t } = useTranslation();

  let errorMessage = "";
  if (searchError === "search_error_short_query") {
    errorMessage = t("Your search must be at least 2 characters long");
  } else if (searchError === "search_error") {
    errorMessage = t("There was an error when searching");
  }

  return (
    <Form className={emr2Styles.searchForm} onSubmit={onSubmit}>
      <InputGroup className={classNames("mb-3")}>
        <Form.Control
          className={classNames("text-body", emr2Styles.searchBox)}
          type="search"
          ref={inputRef}
          placeholder={t("Enter health institution name")}
          enterKeyHint="search"
        />
      </InputGroup>
      {searchError ? <p className="text-danger">{errorMessage}</p> : null}
      <Form.Control
        className={classNames(
          "text-body bg-white border",
          emr2Styles.stateSelector,
        )}
        as="select"
        defaultValue=""
        onChange={(e) => {
          onStateFilter(e.target.value);
        }}
      >
        <option key="placeholder" value="" disabled>
          {t("Select a state")}
        </option>
        {Object.keys(STATES).map((code) => {
          return (
            <option key={code} value={code}>
              {STATES[code]}
            </option>
          );
        })}
      </Form.Control>
    </Form>
  );
}

const STATES: {
  [code: string]: string;
} = {
  all: "All",
  AK: "AK - Alaska",
  AL: "AL - Alabama",
  AR: "AR - Arkansas",
  AZ: "AZ - Arizona",
  CA: "CA - California",
  CO: "CO - Colorado",
  CT: "CT - Connecticut",
  DE: "DE - Delaware",
  FL: "FL - Florida",
  GA: "GA - Georgia",
  HI: "HI - Hawaii",
  IA: "IA - Iowa",
  ID: "ID - Idaho",
  IL: "IL - Illinois",
  IN: "IN - Indiana",
  KS: "KS - Kansas",
  KY: "KY - Kentucky",
  LA: "LA - Louisiana",
  MA: "MA - Massachusetts",
  MD: "MD - Maryland",
  ME: "ME - Maine",
  MI: "MI - Michigan",
  MN: "MN - Minnesota",
  MO: "MO - Missouri",
  MS: "MS - Mississippi",
  MT: "MT - Montana",
  NC: "NC - North Carolina",
  ND: "ND - North Dakota",
  NE: "NE - Nebraska",
  NH: "NH - New Hampshire",
  NJ: "NJ - New Jersey",
  NM: "NM - New Mexico",
  NV: "NV - Nevada",
  NY: "NY - New York",
  OH: "OH - Ohio",
  OK: "OK - Oklahoma",
  OR: "OR - Oregon",
  PA: "PA - Pennsylvania",
  RI: "RI - Rhode Island",
  SC: "SC - South Carolina",
  SD: "SD - South Dakota",
  TN: "TN - Tennessee",
  TX: "TX - Texas",
  UT: "UT - Utah",
  VA: "VA - Virginia",
  VT: "VT - Vermont",
  WA: "WA - Washington",
  WI: "WI - Wisconsin",
  WV: "WV - West Virginia",
  WY: "WY - Wyoming",
};

type ThankYouModalProps = {
  show: boolean;
  onSearchAnother: () => void;
  onSkip: () => void;
  loading: boolean;
};

function ThankYouModal(props: ThankYouModalProps) {
  const { show, onSearchAnother, onSkip, loading } = props;
  const { t } = useTranslation();
  return (
    <Modal
      animation={false}
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`consent-modal ${utils.get_browser()}`}
      backdrop="static"
    >
      {loading ? (
        <Modal.Body>
          <div
            className={classNames(
              "d-flex flex-column align-items-center p-4",
              emr2Styles.thankYouMinHeight,
            )}
          >
            <Spinner />
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <div className="d-flex flex-column align-items-center p-4">
            <img src={checkIconUrl} alt="" />
            <h4
              className={classNames(
                "text-center mb-3",
                emr2Styles.iconPageTitleH4,
              )}
            >
              {t("Thanks for letting us know")}
            </h4>
            <p className="text-center mb-0">
              {t("Your feedback can help us improve this feature.")}
            </p>
          </div>
          <div className="text-center mb-2">
            <Button
              variant="primary"
              size="sm"
              type="button"
              className={classNames("px-3 py-2", emr2Styles.roundedSm)}
              onClick={() => {
                onSearchAnother();
              }}
            >
              {t("Search for another EHR")}
            </Button>
          </div>
          <div className="text-center mb-3">
            <Button
              className={classNames(
                "font-weight-normal",
                emr2Styles.linkButton,
              )}
              variant="link"
              size="sm"
              onClick={() => {
                onSkip();
              }}
            >
              {t("Skip this for now")}
            </Button>
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
}
