import { SURVEY_STARTED } from "../constants/api";

export const utils_survey = {
  getCompletedSurveys: (surveys) =>
    surveys
      .filter(
        (survey) => survey.completed && survey.completed !== SURVEY_STARTED,
      )
      .sort((a, b) => (a.completed > b.completed ? 1 : -1)),

  getUncompletedSurveys: (surveys) =>
    surveys.filter(
      (survey) => !survey.completed || survey.completed === SURVEY_STARTED,
    ),

  getStartedSurveys: (surveys) =>
    surveys.filter((survey) => survey.completed === SURVEY_STARTED),

  getNotStartedSurveys: (surveys) =>
    surveys.filter((survey) => !survey.completed),
  isSurveyPath: (path) =>
    /\/(surveys\/start|enrollment)\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/.test(
      path,
    ),
};
