import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styles from "./DnaKitOnsite.module.scss";
import dnaKit from "src/assets/images/dna-kit.svg";
import dnaInstructions from "src/assets/images/dna-instructions.svg";
import dnaSample from "src/assets/images/dna-sample.svg";
import checkmark from "src/assets/images/checkmark-green.svg";
import { utils } from "src/utils/utils_general";
import { LOCAL_STORAGE } from "src/constants/localStorage";

type StepStatus = {
  isComplete: boolean;
  isCollapsed: boolean;
};

type OnsiteSteps = {
  stepOne: StepStatus;
  stepTwo: StepStatus;
  stepThree: StepStatus;
};

type DnaKitOnsiteProps = {
  kitCode: string;
  onStepComplete: () => void;
};

function DnaKitOnsite({ kitCode, onStepComplete }: DnaKitOnsiteProps) {
  const { t } = useTranslation();
  const [onsiteSteps, setOnsiteSteps] = useState<OnsiteSteps>(() => {
    return (
      utils.get_local_storage(LOCAL_STORAGE.DNA_KIT_ONSITE_STEPS) || {
        stepOne: { isComplete: false, isCollapsed: false },
        stepTwo: { isComplete: false, isCollapsed: false },
        stepThree: { isComplete: false, isCollapsed: false },
      }
    );
  });

  useEffect(() => {
    utils.set_local_storage(LOCAL_STORAGE.DNA_KIT_ONSITE_STEPS, onsiteSteps);
  }, [onsiteSteps, LOCAL_STORAGE.DNA_KIT_ONSITE_STEPS]);

  const handleStepComplete = async (step: string) => {
    switch (step) {
      case "stepOne":
        setOnsiteSteps((prevState) => ({
          ...prevState,
          stepOne: {
            ...prevState.stepOne,
            isComplete: true,
            isCollapsed: true,
          },
        }));
        break;
      case "stepTwo":
        setOnsiteSteps((prevState) => ({
          ...prevState,
          stepTwo: {
            ...prevState.stepTwo,
            isComplete: true,
            isCollapsed: true,
          },
        }));
        break;
      case "stepThree":
        setOnsiteSteps((prevState) => ({
          ...prevState,
          stepThree: {
            ...prevState.stepThree,
            isComplete: true,
            isCollapsed: true,
          },
        }));
        break;
      default:
        break;
    }
  };

  const handleOnClick = () => {
    utils.remove_local_storage(LOCAL_STORAGE.DNA_KIT_ONSITE_STEPS);
    onStepComplete();
  };

  return (
    <div className="dna-kit-onsite-workflow">
      <OnsiteStep
        number={1}
        title={t("Kit_Onsite_One_Title")}
        description={t("Kit_Onsite_One_Description")}
        image={dnaKit}
        kitCode={kitCode}
        btnText={t("Kit_Onsite_One_Button_Text")}
        collapsedText={t("Kit_Onsite_One_Collapsed_Text")}
        isComplete={onsiteSteps.stepOne.isComplete}
        isCollapsed={onsiteSteps.stepOne.isCollapsed}
        isEnabled={!onsiteSteps.stepOne.isComplete}
        onComplete={() => handleStepComplete("stepOne")}
      />

      <OnsiteStep
        number={2}
        title={t("Kit_Onsite_Two_Title")}
        description={t("Kit_Onsite_Two_Description")}
        image={dnaInstructions}
        btnText={t("Kit_Onsite_Two_Button_Text")}
        collapsedText={t("Kit_Onsite_Two_Collapsed_Text")}
        isComplete={onsiteSteps.stepTwo.isComplete}
        isCollapsed={onsiteSteps.stepTwo.isCollapsed}
        isEnabled={onsiteSteps.stepOne.isComplete}
        onComplete={() => handleStepComplete("stepTwo")}
      />

      <OnsiteStep
        number={3}
        title={t("Kit_Onsite_Three_Title")}
        description={t("Kit_Onsite_Three_Description")}
        image={dnaSample}
        kitCode={kitCode}
        btnText={t("Kit_Onsite_Three_Button_Text")}
        collapsedText={t("Kit_Onsite_Three_Collapsed_Text")}
        isComplete={onsiteSteps.stepThree.isComplete}
        isCollapsed={onsiteSteps.stepThree.isCollapsed}
        isEnabled={onsiteSteps.stepTwo.isComplete}
        onComplete={() => handleStepComplete("stepThree")}
        displayKeycodeCaption={true}
      />

      {onsiteSteps.stepThree.isComplete && (
        <Button className={styles.continueButton} onClick={handleOnClick}>
          {t("Continue")}
        </Button>
      )}
    </div>
  );
}

type OnsiteStepProps = {
  title: string;
  description?: string;
  number?: number;
  btnText?: string;
  collapsedText?: string;
  isComplete: boolean;
  isCollapsed: boolean;
  isEnabled: boolean;
  onComplete: () => void;
  kitCode?: string;
  image?: string;
  displayKeycodeCaption?: boolean;
};

function OnsiteStep({
  title,
  description,
  number,
  btnText,
  collapsedText,
  isComplete,
  isCollapsed,
  isEnabled,
  onComplete,
  kitCode,
  image,
  displayKeycodeCaption,
}: OnsiteStepProps) {
  const { t } = useTranslation();
  return (
    <div
      className={`${styles.onsiteStep} 
        ${isComplete ? styles.complete : ""} 
        ${isCollapsed ? styles.collapsed : ""}
        ${isEnabled ? styles.active : ""}`}
    >
      {isCollapsed ? (
        <div
          className={`${!isEnabled && !isComplete ? styles.disabledText : ""}`}
        >
          <div className={styles.collapsedHeader}>
            <img src={checkmark} alt="checkmark" />
            <h3>{collapsedText}</h3>
          </div>
          {displayKeycodeCaption && (
            <div className={styles.stepKeycode}>
              <p>{t("Kit_Onsite_Three_Keycode_Caption")}:</p>
              <span>{kitCode?.toUpperCase()}</span>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className={styles.stepHeader}>
            {number && (
              <div
                className={`${styles.stepNumber} ${!isEnabled && !isComplete ? styles.disabledNumber : ""}`}
              >
                {number}
              </div>
            )}
          </div>
          <div className={styles.stepContent}>
            <div className={styles.textContent}>
              <h3
                className={`${!isEnabled && !isComplete ? styles.disabledText : ""}`}
              >
                {title}
              </h3>
              {description && (
                <p
                  className={`${styles.descriptionText} ${!isEnabled && !isComplete ? styles.disabledText : ""}`}
                >
                  {description}
                </p>
              )}
              {kitCode && (
                <div
                  className={`${styles.kitCode} ${!isEnabled && !isComplete ? styles.disabledText : ""}`}
                >
                  {kitCode?.toUpperCase()}
                </div>
              )}
            </div>
            {image && (
              <img
                src={image}
                alt={title}
                className={`${styles.stepImage} ${!isEnabled && !isComplete ? styles.disabledImage : ""}`}
              />
            )}
          </div>
          <Button
            variant="secondary"
            disabled={!isEnabled}
            onClick={onComplete}
            className={styles.stepButton}
          >
            <span>{btnText}</span>
            <i className={styles.arrowRight} />
          </Button>
        </>
      )}
    </div>
  );
}

export default DnaKitOnsite;
