import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import AppLogo from "../../assets/images/app-logo.svg";
import LanguageSwitch from "./language_switch";
import { CONFIG, hasLanguageSwitcher } from "../../constants/config";
import PAGES from "src/constants/pages";
import { utils } from "src/utils/utils_general";
import { utils_user } from "src/utils/utils_user";
import Logout from "./logout";
import { TRUNCATE } from "src/constants/truncateString";

export class UnauthWebPageHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuOpen: 0,
    };
  }

  toggleMobileMenu() {
    this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen });
  }

  getLoginSection(logged) {
    const { t, user } = this.props;
    if (logged) {
      const name = utils_user.get_user_name(user);
      return (
        <div className="unauth-header nav-name name font-callout">
          <div>{utils.truncate_string(name, TRUNCATE.NAME)}</div>
          <Logout />
        </div>
      );
    } else {
      return (
        <div className="landing-header-menu-buttons">
          <a href={PAGES.LOGIN}>{t("Log In")}</a>
          <a href={PAGES.SIGNUP} className="_highlighted">
            {t("Sign Up")}
          </a>
        </div>
      );
    }
  }

  render() {
    const { t, user } = this.props;
    const logged = utils_user.is_logged(user);
    const loggedClass = logged ? "logged" : "";
    return (
      <div className={`unauth-header ${loggedClass}`}>
        <div className="unauth-header-logo">
          <Link to="/">
            <img src={AppLogo} alt={`${CONFIG.APP_NAME} logo`} />
          </Link>
        </div>
        <div
          className={`landing-header-menu-hamburger ${this.state.mobileMenuOpen ? "_open" : "_closed"}`}
          onClick={() => this.toggleMobileMenu()}
        >
          <span className="hamburger-strip" />
          <span className="hamburger-strip" />
          <span className="hamburger-strip" />
        </div>
        <div
          className={`unauth-header-landing-menu ${this.state.mobileMenuOpen ? "_open" : "_closed"}`}
        >
          <div className="landing-header-menu-links">
            <a href={PAGES.HOME_PAGE}>{t("Home")}</a>
          </div>
          {hasLanguageSwitcher ? (
            <div className="landing-header-menu-language">
              <LanguageSwitch />
            </div>
          ) : null}
          {this.getLoginSection(logged)}
        </div>
      </div>
    );
  }
}

export default withTranslation()(UnauthWebPageHeader);
