import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import classNames from "classnames";
import PAGES from "../../constants/pages";
import { CONFIG, hasLanguageSwitcher } from "../../constants/config";
import LanguageSwitch from "../global/language_switch";
import { utils } from "../../utils/utils_general";
import AppLogo from "../../assets/images/app-logo.svg";
import { useSelector } from "react-redux";
import { TRUNCATE } from "../../constants/truncateString";
import Logout from "../global/logout";
import faqImgUrl from "../../assets/icons/faq.svg";

import styles from "./emr_connect_layout.module.css";

export function EMRConnectLayout(props: any) {
  const { aside = {}, onShowIntro, introShown, children } = props;

  const { t } = useTranslation();
  const { name } = aside || {};
  return (
    <div>
      <EMRConnectHeader />
      <section className={classNames(styles.container, "container py-md-5")}>
        <div className="row">
          <aside
            className={classNames(
              "col-12 col-lg-3 col-xl-2 px-4 pt-3",
              styles.leftAside,
            )}
          >
            <div className="mt-2 mt-sm-0 mb-3 d-flex justify-content-between">
              <h2 className={classNames("h3", styles.h2)}>{t(name)}</h2>
              {!introShown ? (
                <div className="">
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="more-info-tooltip">
                        {t("More information")}
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="link"
                      className={classNames(styles.moreInfoButton)}
                      onClick={() => onShowIntro(true)}
                    >
                      <img src={faqImgUrl} alt={t("More information")} />
                    </Button>
                  </OverlayTrigger>
                </div>
              ) : null}
            </div>
          </aside>
          <div className="col-12 col-lg-9 col-xl-8 px-0 px-lg-5">
            <div className={classNames(styles.main, "bg-white p-4 p-sm-4")}>
              {children}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

function EMRConnectHeader(props: any) {
  const { workflowName } = props;
  const { t } = useTranslation();
  // @ts-ignore
  const user = useSelector((state) => state.loginUser);
  let name = "";
  if (user.first_name && user.last_name) {
    name = `${user.first_name} ${user.last_name}`;
  } else if (user.first_name) {
    name = user.first_name;
  }
  return (
    <header className={classNames(styles.header, "py-3 border-0")}>
      <div className={`container-fluid pr-xl-5 pl-xl-5 pb-sm-4`}>
        <div className="row">
          <div className="col-6">
            <div className="header-logo">
              <Link to={PAGES.DASHBOARD}>
                <img src={AppLogo} alt={`${CONFIG.APP_NAME} logo`} />
              </Link>
            </div>
          </div>
          <div className="col-6 text-right header-signout">
            <div>
              {hasLanguageSwitcher ? (
                <div className="nav-language">
                  {" "}
                  <LanguageSwitch />
                </div>
              ) : null}
              <div className="nav-name name font-callout">
                {utils.truncate_string(name, TRUNCATE.NAME)}
              </div>
            </div>
            <div className="font-callout">
              <a style={{ color: "white" }} href={PAGES.PROFILE}>
                {t("Your profile")}
              </a>
            </div>
            <Logout />
          </div>
        </div>
      </div>
      <div className="header-workflow-content">
        <div className="header-workflow-progress">
          <div className="workflow-title">
            <h1>{workflowName}</h1>
          </div>
        </div>
      </div>
    </header>
  );
}
