import { ReactComponent as AppLogo } from "../../assets/images/app-logo-loading.svg";

export const PageLoader = (props) => (
  <div
    className="dashboard-loader"
    style={!props.loader ? { opacity: 0 } : { opacity: 1 }}
  >
    {props.blankPage ? null : (
      <div className="dashboard-loader-wrapper">
        <AppLogo
          className="rgc-spinner"
          style={{ width: "100%", height: "auto", minWidth: "250px" }}
          fill="#005ca3"
        />
        {props.loaderMessage ? (
          <div className="mt-2 text-center font-callout">
            {props.loaderMessage}
          </div>
        ) : null}
      </div>
    )}
  </div>
);

export default PageLoader;
