import { REDUX_ACTION } from "../constants/reduxAction";

const DEFAULT_STATE = null;

function selfRegContent(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case REDUX_ACTION.SELF_REG_CONTENT_GET:
      return action.data;
    default:
      return state;
  }
}

export default selfRegContent;
