import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  config_privacy_get,
  config_terms_get,
} from "../../actions/configAction";
import { CONFIG } from "src/constants/config";

import CopyrightText from "../global/copyright_text";
import CobrandingLogo from "../global/cobranding_logo";
import HtmlContentComponent from "../core/HtmlContent/HtmlContentComponent";

class SiteMapFooter extends React.Component {
  prefetchPrivacyPolicy() {
    if (!this.props.privacyContent) {
      this.props.config_privacy_get(true);
    }
  }

  prefetchTerms() {
    if (!this.props.termsContent) {
      this.props.config_terms_get(true);
    }
  }

  getNumberOfColumns() {
    const { t } = this.props;
    let columns = {
      footer_description: false,
      column1_footer: false,
      column2_footer: false,
      column3_footer: false,
    };

    if (
      t("texts:Landing_footer_description") !== null &&
      t("texts:Landing_footer_description") !== " "
    ) {
      columns.footer_description = true;
    }
    if (
      t("texts:Column1_footer") !== null &&
      t("texts:Column1_footer") !== " "
    ) {
      columns.column1_footer = true;
    }
    if (
      t("texts:Column2_footer") !== null &&
      t("texts:Column2_footer") !== " "
    ) {
      columns.column2_footer = true;
    }
    if (
      t("texts:Column3_footer") !== null &&
      t("texts:Column3_footer") !== " "
    ) {
      columns.column3_footer = true;
    }
    return columns;
  }

  render() {
    const { t } = this.props;
    const columns = this.getNumberOfColumns();
    return (
      <footer className={`footer white pt-3 pb-3`}>
        <div className="container-fluid footer-content">
          <div className="row no-gutters">
            {columns.footer_description ? (
              <div
                className={`col-12 col-xl text-center text-xl-left ${CONFIG.COBRANDING_LOGO_ENABLED ? "pt-xl-2" : "pt-xl-3"}`}
              >
                <HtmlContentComponent
                  tag={"div"}
                  markup={t("texts:Landing_footer_description")}
                />
              </div>
            ) : null}
            <div
              className={`col-12 col-xl text-center text-xl-left ${CONFIG.COBRANDING_LOGO_ENABLED ? "pt-xl-2" : "pt-xl-3"}`}
            >
              <HtmlContentComponent
                tag={"div"}
                markup={t("texts:Column1_footer")}
              />
            </div>

            <div
              className={`col-12 col-xl text-center text-xl-left ${CONFIG.COBRANDING_LOGO_ENABLED ? "pt-xl-2" : "pt-xl-3"}
                                ${!columns.footer_description ? "column-footer-center" : null}`}
            >
              <HtmlContentComponent
                tag={"div"}
                markup={t("texts:Column2_footer")}
              />
            </div>

            <div
              className={`col-12 col-xl text-center text-xl-left ${CONFIG.COBRANDING_LOGO_ENABLED ? "pt-xl-2" : "pt-xl-3"} 
                                ${!columns.footer_description ? "column-footer-right" : null}`}
            >
              <HtmlContentComponent tag={"div"} markup={t("Column3_footer")} />
            </div>
          </div>
        </div>
        <div className="container-fluid ">
          <div className="row no-gutters">
            <div className="col-12 col-xl-2 text-left desktop-only pt-0 pt-xl-3">
              <CopyrightText />
            </div>
            <div
              className={`col-12 offset-xl-8 col-xl-2 text-center text-xl-center ${CONFIG.COBRANDING_LOGO_ENABLED ? "pt-xl-0" : "pt-xl-3"}`}
            >
              <CobrandingLogo />
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state,
  ...ownProps,
});

export default withRouter(
  connect(mapStateToProps, { config_privacy_get, config_terms_get })(
    withTranslation()(SiteMapFooter),
  ),
);
