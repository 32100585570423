import React from "react";
import { Link } from "react-router-dom";
import { Trans, withTranslation, useTranslation } from "react-i18next";
import { ReactComponent as Character2 } from "../../assets/images/unauth-character-2.svg";
import { ReactComponent as Character3 } from "../../assets/images/unauth-character-3.svg";
import { ReactComponent as Character2Mobile } from "../../assets/images/unauth-character-2-mobile.svg";
import LanguageSwitch from "./language_switch";

import AppLogo from "../../assets/images/app-logo.svg";
import PAGES from "src/constants/pages";
import { COPY_WELCOME_MESSAGE } from "../../constants/copyPageDescriptions";
import { CONFIG, hasLanguageSwitcher } from "../../constants/config";

const UnauthHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="unauth-header">
      <div className="row">
        <div className="col-8">
          <div className="unauth-header-logo">
            <Link to={PAGES.LOGIN}>
              <img src={AppLogo} alt={`${CONFIG.APP_NAME} logo`} />
            </Link>
          </div>
        </div>
        {hasLanguageSwitcher ? (
          <div className="col-4 text-right mt-2">
            <LanguageSwitch />
          </div>
        ) : null}
      </div>
      <div className="unauth-welcome">
        <p className="unauth-title">{t("Welcome to")}</p>
        <h1>{t("Welcome_Title")}</h1>
        <div className="unauth-title-description">
          <h2>{t("Welcome_Description")}</h2>
        </div>

        <p className="unauth-description">
          <Trans i18nKey="COPY_WELCOME_MESSAGE">{COPY_WELCOME_MESSAGE}</Trans>
        </p>
      </div>

      <div className="unauth-character-left-desktop">
        {" "}
        <Character2 />{" "}
      </div>
      <div className="unauth-character-right-desktop">
        {" "}
        <Character3 />{" "}
      </div>
      <div className="unauth-character-right-mobile">
        {" "}
        <Character2Mobile />{" "}
      </div>

      <div className="unauth-gradient" />
    </div>
  );
};

export default withTranslation()(UnauthHeader);
