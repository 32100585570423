import React from "react";
import { connect } from "react-redux";
import { post_user_attributes_post } from "src/actions/userAction";
import {
  GenericWorkflowStep,
  WorkflowStepProps,
} from "src/services/types/WorkflowTypes";
import { utils_workflow } from "src/utils/utils_workflow";

// These are the props directly used when returning the component: <WorkflowNextStepWrapper prop1={} prop2={} />
export type WorkflowNextStepWrapperComponentProps = {
  step: GenericWorkflowStep;
  onFormComplete: () => void;
  onWorkflowExit: () => Promise<any> | null;
  onWorkflowSkip: () => Promise<any> | null;
  stepComponent: React.FunctionComponent<WorkflowStepProps>;
};

type WorkflowNextStepWrapperDispatchProps = {
  post_user_attributes_post: (
    code: string,
    value: string,
    hideSpinner?: boolean,
  ) => Promise<void>;
};

export type WorkflowNextStepWrapperProps =
  WorkflowNextStepWrapperComponentProps & WorkflowNextStepWrapperDispatchProps;

function WorkflowNextStepWrapper({
  step,
  stepComponent: StepComponent,
  onFormComplete,
  onWorkflowExit,
  onWorkflowSkip,
  post_user_attributes_post,
}: WorkflowNextStepWrapperProps) {
  const handleComponentComplete = async () => {
    const attributes = utils_workflow.getPostAttributes(
      step.exit_attribute,
    ) as Record<string, string>;
    await Promise.all(
      Object.entries(attributes).map(([attr, value]) =>
        post_user_attributes_post(attr, value),
      ),
    );

    onFormComplete();
  };
  return (
    <div className="workflow-component-wrapper">
      <StepComponent
        currentStep={step}
        onComponentComplete={handleComponentComplete}
        onExitWorkflow={onWorkflowExit}
        onSkipWorkflow={onWorkflowSkip}
      />
    </div>
  );
}

const mapDispatchToProps = (
  dispatch: any,
): WorkflowNextStepWrapperDispatchProps => ({
  post_user_attributes_post: (
    code: string,
    value: string,
    hideSpinner?: boolean,
  ) => post_user_attributes_post(code, value, hideSpinner as any)(dispatch),
});

export default connect(null, mapDispatchToProps)(WorkflowNextStepWrapper);
