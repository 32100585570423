import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { FEATURE } from "../../constants/features";
import PAGES from "src/constants/pages";
import { ancestrystatus_get } from "../../actions/ancestryAction";
import { utils } from "../../utils/utils_general";
// import Spinner from '../global/spinner';

class AncestryMessage extends React.Component {
  constructor() {
    super();
    this.state = {
      status: null,
    };
  }

  componentDidMount() {
    this.props
      .ancestrystatus_get(true)
      .then((res) => this.setState({ status: res }));
  }

  componentWillUnmount() {
    this._isMounted = false;
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {};
  }

  render() {
    const noAncestryAvail =
      this.props.ancestryStatus && this.props.ancestryStatus.title === "";

    if (noAncestryAvail || noAncestryAvail === null) {
      return null;
    }
    if (
      this.state.status &&
      !utils.is_obj_empty(this.state.status) &&
      this.state.status.title &&
      this.props.featureFlag &&
      this.props.featureFlag[FEATURE.ANCESTRY]
    ) {
      return null;
    }
    if (window.location.pathname.includes(PAGES.SURVEY_COMPLETE)) {
      return null;
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, { ancestrystatus_get })(
  withTranslation()(AncestryMessage),
);
