//@ts-check

import { Button, Modal } from "react-bootstrap";
import highfive from "../../assets/images/high-five.svg";
import { useTranslation } from "react-i18next";
import styles from "./emr_empty_results_thank_you_banner.module.scss";

/**
 * Modal to show when there are no results from the EHR
 * @param {Object} props
 * @param {boolean} props.show
 * @param {string} props.searchType
 * @param {() => void} props.onRequestClose
 * @param {() => void} props.onSkip
 * @returns {JSX.Element}
 */
const EmrEmptyResultsThankYouBanner = ({
  show,
  searchType,
  onRequestClose,
  onSkip,
}) => {
  const { t } = useTranslation();

  // @ts-ignore
  const translationKeys = {
    title: `emr.empty_results.${searchType}.title`,
    description: `emr.empty_results.${searchType}.description`,
    searchButton: `emr.empty_results.${searchType}.search_button`,
    skipButton: `emr.empty_results.${searchType}.skip_button`,
  };

  const defaultTranslations = {
    doctor: {
      title: "Thanks for submitting your doctor information",
      description: "We'll try our best to find your doctor",
      searchButton: "Search for another doctor",
      skipButton: "Skip this step for now",
    },
    healthsystem: {
      title: "Thanks for submitting",
      description: "We'll try to find your health system",
      searchButton: "Search for health system",
      skipButton: "Skip this step for now",
    },
  };

  return (
    <Modal
      dialogClassName={styles.banner}
      show={show}
      onHide={onRequestClose}
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body className="text-center d-flex flex-column">
        <div className="text-center">
          <img src={highfive} alt="hands" />
        </div>
        <h1>
          {t(
            // @ts-ignore
            defaultTranslations[searchType].title,
          )}
        </h1>
        <p>
          {t(
            // @ts-ignore
            defaultTranslations[searchType].description,
          )}
        </p>
        <div>
          <Button
            variant="primary"
            className={styles.searchBtn}
            onClick={onRequestClose}
          >
            {t(
              // @ts-ignore
              defaultTranslations[searchType].searchButton,
            )}
          </Button>
          <div>
            <Button variant="link" className={styles.skipBtn} onClick={onSkip}>
              {t(
                // @ts-ignore
                defaultTranslations[searchType].skipButton,
              )}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EmrEmptyResultsThankYouBanner;
