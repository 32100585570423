import { REDUX_ACTION } from "../constants/reduxAction";

const DEFAULT_STATE = 0;

function recoveryBannerReducer(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case REDUX_ACTION.CONSENT_RECOVERY_BANNER_COUNT:
      return action.data;
    default:
      return state;
  }
}

export default recoveryBannerReducer;
