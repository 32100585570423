import { useState } from "react";
import { useTranslation } from "react-i18next";

import PAGES from "src/constants/pages";
import AppLogo from "../../assets/images/app-logo.svg";

import { CONFIG, hasLanguageSwitcher } from "../../constants/config";
import LanguageSwitch from "../global/language_switch";

// Based on src/components/global/unauth_landing_header.js
export function ReferralLandingPageHeader() {
  const { t } = useTranslation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  // TODO: for some reason, anchor links need to be clicked twice
  return (
    <div className="col-12">
      <div className="unauth-header-logo">
        <a href="/">
          <img src={AppLogo} alt={`${CONFIG.APP_NAME} logo`} />
        </a>
      </div>
      <div
        className={`landing-header-menu-hamburger ${
          mobileMenuOpen ? "_open" : "_closed"
        }`}
        onClick={toggleMobileMenu}
      >
        <span className="hamburger-strip" />
        <span className="hamburger-strip" />
        <span className="hamburger-strip" />
      </div>
      <div
        className={`unauth-header-landing-menu ${
          mobileMenuOpen ? "_open" : "_closed"
        }`}
      >
        <div className="landing-header-menu-links">
          <a href="#section-1" onClick={toggleMobileMenu}>
            {t("Referral_Landing_menu_item_section1")}
          </a>
          <a href="#section-2" onClick={toggleMobileMenu}>
            {t("Referral_Landing_menu_item_section2")}
          </a>
          <a href="#section-3" onClick={toggleMobileMenu}>
            {t("Referral_Landing_menu_item_section3")}
          </a>
        </div>
        {
          // TODO: remove this?
          hasLanguageSwitcher ? (
            <div className="landing-header-menu-language">
              <LanguageSwitch />
            </div>
          ) : null
        }
        <div className="landing-header-menu-buttons">
          <a href={PAGES.SIGNUP} className="_highlighted">
            {t("Sign up now")}
          </a>
        </div>
      </div>
    </div>
  );
}
